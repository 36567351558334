import React from 'react';
import styled from 'styled-components';
import * as Mixins from '../Mixins';
import * as t from '../Typography';
import Layout, { Content } from '../components/Layout';
// import Placeholder from '../images/placeholder.png';
import { HireMe, SocialMedia } from '../components/Button.js';
// import HireMePopup from '../components/HireMePopup.js';
import { media } from '../MediaQueries';
import Colors from '../Colors';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
// import { darken } from 'polished';
import TypedBlock from '../components/TypedBlock';

import { TwitterIcon, FacebookIcon, LinkedinIcon, EmailIcon } from 'react-share';
import BehanceLogo from '../images/behance.png';

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 60px 0;
  ${t.H1} {
    color: ${Colors.darkest};
  }
`;

// const Block = styled.div`
//   &:nth-child(odd) {
//     border: solid 1px ${darken(0.1, Colors.light)};
//     background-color: ${Colors.light};
//   }
// `;

// const BlockContent = styled(Content)`
//   ${Mixins.block}
//   padding: 100px 40px;
//   ${media.tablet`
//     flex-direction: column;
//     align-items: baseline;
//   `};
//   ${media.phone`
//     padding: 40px 10px;
//   `};
//   ${t.P} {
//     margin-top: 10px;
//   }
//   ${t.H2} {
//     margin-top: 0;
//   }
//   img {
//     width: 100%;
//     height: auto;
//   }
// `;

// const DivWrapper = styled.div`
//   padding: 80px 30px;
//   ${media.tablet`padding: 50px 0;`}
//   &:first-child {
//     ${media.tablet`
//       margin-bottom: 40px;
//   `};
//   }
// `;

// const ItemImage = styled.img`
//   max-width: 85%;
//   position: relative;
//   ${media.tablet`max-width: none;`}
// `;

const HomepageWrapper = styled.div`
  ${Mixins.wrapper}
  .who-desc {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 90%;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  ${t.H1} {
    margin: 0 0 20px 0;
  }

  .avatar {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
    ${media.tablet`max-width: 70%;`}
  }
  .link {
    padding: 0;
    color: ${Colors.darkest};
    text-decoration: underlined;
    svg {
      margin-left: 7px;
    }
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 42px;
  }
`;

const WorkWithMe = styled.div`
  padding: 80px;
  width: 73%;
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 rgba(57, 55, 55, 0.08);
  background-color: #ffffff;
  text-align: center;
  position: relative;
  margin: 100px auto -150px auto;
  ${t.LargeP} {
    max-width: 80%;
    margin: 0 auto 28px auto;
  }
  ${media.tablet`
    width: auto;
    padding: 40px;
    margin: 50px 30px -100px 30px;
  `};
`;

class Homepage extends React.Component {
  // state = {
  //   openHireMePopup: false
  // };

  // handleRequestDemoClose = () => {
  //   this.setState({
  //     openHireMePopup: false
  //   });
  // };

  openContactPopup = () => {
    window.location.href = 'https://contact.biniljacob.com';
  };

  render() {
    // const { openHireMePopup } = this.state;
    const { data } = this.props;

    var calculate_age = () => {
      var today = new Date();
      var birthDate = new Date(1989, 10, 25);
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      console.log(age_now);
      return age_now;
    };

    return (
      <HomepageWrapper>
        <Layout theme="white" bigFooter openContactPopup={this.openContactPopup}>
          <AboveFold>
            <Img fluid={data.avatarHomepage.childImageSharp.fluid} alt="Binil Jacob" className="avatar" />
            <t.H4 primary align="center">
              Hey, I am
            </t.H4>
            <t.H1 primary align="center">
              Binil Jacob
            </t.H1>
            <t.LargeP align="center" max45>
              <TypedBlock
                strings={[
                  'An <strong>Entrepreneur</strong>',
                  'A <strong>Developer</strong>',
                  'A <strong>Designer</strong>'
                ]}
              />
            </t.LargeP>

            <t.P align="center" max70 className="who-desc">
              <SocialMedia>
                <a href="https://twitter.com/binilj04" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon size={32} round={true} url={'shareUrl'} />
                </a>
              </SocialMedia>{' '}
              <SocialMedia>
                <a href="https://www.facebook.com/binilj04" target="_blank" rel="noopener noreferrer">
                  <FacebookIcon size={32} round={true} />
                </a>
              </SocialMedia>{' '}
              <SocialMedia>
                <a href="https://in.linkedin.com/in/binilj04" target="_blank" rel="noopener noreferrer">
                  <LinkedinIcon size={32} round={true} />
                </a>
              </SocialMedia>{' '}
              <SocialMedia>
                <a href="mailto:binilj04@gmail.com?subject=Hi" rel="noopener noreferrer">
                  <EmailIcon size={32} round={true} />
                </a>
              </SocialMedia>{' '}
              <SocialMedia>
                <a href="https://www.behance.net/biniljacob" target="_blank" rel="noopener noreferrer">
                  <img src={BehanceLogo} width={32} height={32} alt={'Behance'} />
                </a>
              </SocialMedia>
            </t.P>
          </AboveFold>

          <Content>
            <t.H2 primary align="center" bold>
              About me?
            </t.H2>
            <t.P align="center" max70 className="who-desc">
              I am {calculate_age()} years old and pasionate about building products and businesses around technology.
              Have good experience in building camera based applications in Android and IOS apps and lately been
              tinckering in the domain of Machine Learning. Apart from indulging in side hobby projects that keeps me
              updated on the technology, I do audio-visual or digital design projects too that satisfies my creative
              itch.
              {/* <div></div>
                <div
                style={{ display: 'inline', background: 'black', color: 'white' }}
              >
                Black
              </div> {' & '}
              White are actually shades of <div
                style={{ display: 'inline', background: 'gray', color: 'black' }}
              >
                Gray
              </div> */}
            </t.P>
          </Content>

          <WorkWithMe>
            <t.H1 green>Get in touch with me</t.H1>
            <HireMe onClick={this.openContactPopup} book>
              Contact me
            </HireMe>

            {/* <t.LargeP>Fancy working with me? Contact me for more info! </t.LargeP>
            <HireMe onClick={this.openContactPopup} book>
              Contact me
            </HireMe> */}
            {/* <ContactMePopup/> */}
          </WorkWithMe>
        </Layout>

        {/* <HireMePopup open={openHireMePopup} handleClose={this.handleRequestDemoClose} /> */}
      </HomepageWrapper>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    avatarHomepage: file(relativePath: { eq: "avatar.jpg" }) {
      ...fluidImage
    }
  }
`;
